import Head from 'next/head'
import React from 'react'

export default function Index() {
    return (
        <>
            <Head>
                <meta httpEquiv="refresh" content="0; url=/signup"/>
            </Head>
        </>
    )
}
